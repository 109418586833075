<template>
  <div
    class="transition-all duration-500 hdiw-card flex flex-col p-5 cursor-pointer rounded-md"
    :class="{
      'bg-blue-600': active,
      'bg-silver-100': !active,
      'first-step': step === 1
    }"
    :style="{ marginBottom: isLastStep ? 0 : '24px' }"
  >
    <div class="flex gap-6" :class="{'place-items-start':active,'place-items-center':!active}">
      <div
        class="hdiw-card__step relative shadow-icon flex-shrink-0 place-content-center items-center font-bold flex w-10 h-10 box-content rounded-xl bg-white"
      >
        {{ step }}
      </div>
      <div class="w-full" :class="{ 'text-white -mt-1.5': active }">
        <div v-if="!active" class="font-cocogoose text-[14px] xs:text-[15px]">
          {{ title }}
        </div>

        <div class="overflow-hidden" :style="style">
          <div ref="description">
            <div class="font-cocogoose text-[14px] xs:text-[15px]">
              {{ title }}
            </div>
            <span class="font-sans text-[14px] xs:text-[16px] leading-[1.71] xs:leading-relative">
              {{ description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => undefined,
    },
    description: {
      type: String,
      required: false,
      default: () => undefined,
    },
    step: {
      type: Number,
      required: true,
      default: () => 1,
    },
    active: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    isLastStep: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    minHeight: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  computed: {
    style() {
      if (this.isMobile) {
        return {
          maxHeight: this.active ? `${this.minHeight}px` : 0,
        };
      }

      return {
        minHeight: this.active ? `${this.minHeight}px` : 0,
        maxHeight: this.active ? `${this.minHeight}px` : 0,
      };
    },
    isMobile() {
      if (process.server) {
        return true;
      }
      return (this.$paDeviceDetector.isMobile());
    },
  },
  watch: {
    description: {
      immediate: true,
      handler() {
        if (process.client) {
          this.$nextTick(() => {
            this.$emit('heightChange', this.$refs.description.clientHeight);
          });
        }
      },
    },
  },
};
</script>
