const cheqDomains = {
  'parkos.com': { class: 'ct_clicktrue_40762', js: 'https://euob.segreencolumn.com/sxp/i/69f0ab0b54f36d351801f48f4cf8299e.js', nojs: 'https://obseu.segreencolumn.com/ns/69f0ab0b54f36d351801f48f4cf8299e.html?ch=parkos.com' },
  'parkos.co.uk': { class: 'ct_clicktrue_43584', js: 'https://euob.segreencolumn.com/sxp/i/62063fd13f5fcdc2ba6d2b10a26806f5.js', nojs: 'https://obseu.segreencolumn.com/ns/62063fd13f5fcdc2ba6d2b10a26806f5.html?ch=parkos.co.uk' },
  'parkos.com.au': { class: 'ct_clicktrue_43585', js: 'https://euob.segreencolumn.com/sxp/i/f2e1dcf4e9445ab202b0f8d089ed5653.js', nojs: 'https://obseu.segreencolumn.com/ns/f2e1dcf4e9445ab202b0f8d089ed5653.html?ch=parkos.com.au' },
  'parkos.nl': { class: 'ct_clicktrue_43586', js: 'https://euob.segreencolumn.com/sxp/i/57c0eb66626cfd18463a0fd4ee55fb14.js', nojs: 'https://obseu.segreencolumn.com/ns/57c0eb66626cfd18463a0fd4ee55fb14.html?ch=parkos.nl' },
  'parkos.be': { class: 'ct_clicktrue_43588', js: 'https://euob.segreencolumn.com/sxp/i/91085bd8f0cdab7aba6fd5bf1d93d02a.js', nojs: 'https://obseu.segreencolumn.com/ns/91085bd8f0cdab7aba6fd5bf1d93d02a.html?ch=parkos.be' },
  'parkos.fr': { class: 'ct_clicktrue_43589', js: 'https://obseu.segreencolumn.com/ns/4577a6aa41add37915c578da2e3261d9.html?ch=parkos.fr', nojs: 'https://obseu.segreencolumn.com/ns/4577a6aa41add37915c578da2e3261d9.html?ch=parkos.fr' },
  'parkos.es': { class: 'ct_clicktrue_43590', js: 'https://euob.segreencolumn.com/sxp/i/09f1e9ce9a758d48c85ffb48e9a088c7.js', nojs: 'https://obseu.segreencolumn.com/ns/09f1e9ce9a758d48c85ffb48e9a088c7.html?ch=parkos.es' },
  'parkos.it': { class: 'ct_clicktrue_43591', js: 'https://euob.segreencolumn.com/sxp/i/b4a362dc136a926c7f8e42a068fbfbe4.js', nojs: 'https://obseu.segreencolumn.com/ns/b4a362dc136a926c7f8e42a068fbfbe4.html?ch=parkos.it' },
  'parkos.se': { class: 'ct_clicktrue_43592', js: 'https://euob.segreencolumn.com/sxp/i/a7c108052bdcbf99b0b4e14ea7f6b582.js', nojs: 'https://obseu.segreencolumn.com/ns/a7c108052bdcbf99b0b4e14ea7f6b582.html?ch=parkos.se' },
  'parkos.pl': { class: 'ct_clicktrue_43593', js: 'https://euob.segreencolumn.com/sxp/i/71e4526c187bfcc602e40339f40378f2.js', nojs: 'https://obseu.segreencolumn.com/ns/71e4526c187bfcc602e40339f40378f2.html?ch=parkos.pl' },
  'fr.parkos.be': { class: 'ct_clicktrue_43594', js: 'https://euob.segreencolumn.com/sxp/i/48de3d2ddef4a2d9899f705b96bedf2b.js', nojs: 'https://obseu.segreencolumn.com/ns/48de3d2ddef4a2d9899f705b96bedf2b.html?ch=fr.parkos.be' },
  'eu.parkos.com': { class: 'ct_clicktrue_43595', js: 'https://euob.segreencolumn.com/sxp/i/8cca6e5edee28e1685fe96c10e0bf3c9.js', nojs: 'https://obseu.segreencolumn.com/ns/8cca6e5edee28e1685fe96c10e0bf3c9.html?ch=eu.parkos.com' },
  'parkos.de': { class: 'ct_clicktrue_43596', js: 'https://euob.segreencolumn.com/sxp/i/424ef4209520f812bf5ea1387aa25f12.js', nojs: 'https://obseu.segreencolumn.com/ns/424ef4209520f812bf5ea1387aa25f12.html?ch=parkos.de' },
  'parkos.pt': { class: 'ct_clicktrue_43597', js: 'https://euob.segreencolumn.com/sxp/i/1b6a61605c3201ec6ef6e1fb25bb6e19.js', nojs: 'https://obseu.segreencolumn.com/ns/1b6a61605c3201ec6ef6e1fb25bb6e19.html?ch=parkos.pt' },
  'parkos.at': { class: 'ct_clicktrue_43598', js: 'https://euob.segreencolumn.com/sxp/i/aa98be175be9a6124da013e5c02fe2f7.js', nojs: 'https://obseu.segreencolumn.com/ns/aa98be175be9a6124da013e5c02fe2f7.html?ch=parkos.at' },
  'parkos.dk': { class: 'ct_clicktrue_43599', js: 'https://euob.segreencolumn.com/sxp/i/fef0e8a2d4636f32b76579d220ebdcae.js', nojs: 'https://obseu.segreencolumn.com/ns/fef0e8a2d4636f32b76579d220ebdcae.html?ch=parkos.dk' },
};

export default cheqDomains;
