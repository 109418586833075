<template>
  <div class="stepper font-sans">
    <div v-if="title">
      <h2 class="text-base xs:text-xl md:text-[28px] font-cocogoose mb-4">
        {{ title }}
      </h2>
    </div>
    <div class="stepper__content flex gap-5">
      <div class="hidden md:block w-full relative">
        <nuxt-img
          class="rounded-md w-full h-full object-cover absolute top-0 left-0"
          :src="image"
          :alt="imageAlt"
          loading="lazy"
        />
      </div>
      <div class="stepper__content__steps relative w-full">
        <div ref="line" class="stepper__content__steps__line" />
        <div>
          <div
            v-for="(step, index) in steps"
            :key="step.title"
            @click.prevent="change(index)"
            @mouseover="change(index)"
          >
            <Card
              ref="card"
              :active="activeIndex === index"
              class="mb-6 relative z-10"
              :title="step.title"
              :description="step.description"
              :step="index + 1"
              :is-last-step="index === steps.length - 1"
              :class="{'mb-0':index === steps.length - 1}"
              :min-height="maxHeight"
              @heightChange="heightChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';

export default {
  components: {
    Card,
  },
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    duration: {
      type: Number,
      required: false,
      default: 5000,
    },
  },
  data() {
    return {
      changing: false,
      interval: undefined,
      activeIndex: 0,
      maxHeight: 0,
    };
  },
  computed: {
    image() {
      if (!this.currentStep.img) {
        return '#';
      }
      return this.currentStep.img;
    },
    currentStep() {
      return this.steps[this.activeIndex];
    },
    imageAlt() {
      if (!this.currentStep.alt) {
        return '';
      }
      return this.currentStep.alt;
    },
  },
  mounted() {
    if (!this.$refs.card.length) {
      return;
    }
    const { line } = this.$refs;
    const card = this.$refs.card[0];
    const stepEl = card.$el.querySelector('.hdiw-card__step');
    if (!stepEl) {
      return;
    }
    const rectParent = card.$el.getBoundingClientRect();
    const rect = stepEl.getBoundingClientRect();
    line.style.left = `${(rect.x + rect.width / 2) - rectParent.x}px`;

    this.registerInterval();
  },
  methods: {
    registerInterval() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.changing = true;
        setTimeout(() => {
          this.changing = false;
        }, 500);
        if (this.activeIndex === this.steps.length - 1) {
          this.activeIndex = 0;
        } else {
          this.activeIndex += 1;
        }
      }, this.duration);
      return this.interval;
    },
    change(index) {
      this.changing = true;
      this.activeIndex = index;
      setTimeout(() => {
        this.changing = false;
        this.registerInterval();
      }, 500);
    },
    heightChange(height) {
      if (height > this.maxHeight) {
        this.maxHeight = height;
      }
    },
  },
};
</script>

<style lang="scss">
.stepper {
  &__content {
    &__steps {
      &__line {
        @apply absolute border-l border-silver-200 border bottom-0 top-0 -ml-px;
      }
    }
  }

}
</style>
