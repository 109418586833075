<template>
  <div class="reviews-wrapper flex flex-col py-10 md:py-20">
    <div
      class="flex flex-row place-content-between place-items-center font-sans"
    >
      <div
        v-if="totalScore"
        class="font-cocogoose leading-loose text-base xs:text-lg md:text-[28px] w-11/12 sm:w-8/12 md:w-1/2"
      >
        {{
          $i18n('templates.customer-parking-reviews-title', {
            airportName: airport.name,
            reviewsScoreAvg: totalScore,
          })
        }}
        <svg
          class="inline mb-1 md:mb-2"
          :width="16"
          :height="16"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <rect
              class="reviews-stars__star__background"
              fill="#f06b1f"
              width="20"
              height="20"
              rx="2"
            />
            <path
              class="reviews-stars__star__icon"
              fill="#fff"
              d="m10 12.614-3.919 2.78 1.433-4.586L3.66 7.94l4.804-.055L10 3.333l1.536 4.552 4.804.055-3.854 2.868 1.433 4.585z"
            />
            <rect width="10" height="20" rx="2" />
          </g>
        </svg>
      </div>
    </div>
    <div class="-mx-2.5 md:-mx-3.75 relative">
      <Carousel
        v-if="hasReviewsToShow"
        :class="!show ? 'invisible' : 'visible'"
        @changed="onSlideChanged"
        class="w-full"
      >
        <template #slides>
          <div v-for="review in reviews" :key="review.id">
            <ReviewCard class="mx-2.5 md:mx-3.75" :review="review" />
          </div>
        </template>
      </Carousel>
      <div v-if="!show" class="loading-container">
        <div class="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '~/components/carousel/Carousel.vue';
import ReviewCard from '~/components/reviews/ReviewCard.vue';
import { getCookie, nginxDecodeUserId} from "~/helpers/cookieHelpers";

export default {
  components: {
    Carousel,
    ReviewCard,
  },
  data() {
    return { show: false };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    airportReviewsContent() {
      return this.$store.state.airportReviewsPageContent;
    },
    reviews() {
      return this.$store.state.reviews;
    },
    reviewsMeta() {
      return (
        this.$store.state.reviewsMeta || { reviews: { score: 0, count: 0 } }
      );
    },
    totalScore() {
      if (!this.reviewsMeta) {
        return 0;
      }
      if (this.language.lang === 'en-us') {
        return this.numberFormat(
          (this.reviewsMeta.reviews.score / 2)?.toFixed(1).replace(/[.,]0$/, ''),
        );
      }
      return this.numberFormat(
        this.reviewsMeta.reviews.score?.toFixed(1).replace(/[.,]0$/, ''),
      );
    },
    airport() {
      return this.$store.state.airport;
    },
    hasReviewsToShow() {
      return this.reviews && this.reviews.length;
    },
    count() {
      return this.reviewsMeta.reviews.count === 0
        ? 531
        : this.reviewsMeta.reviews.count;
    },
    airportReviewsLink() {
      if (!this.airportReviewsContent || !this.airportReviewsContent) {
        return '#';
      }
      return `${this.airportReviewsContent.slug}.html`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.show = true;
    });
  },
  methods: {
    numberFormat(number) {
      return new Intl.NumberFormat(this.language.lang).format(number);
    },
    onSlideChanged() {
      this.sendEvent("slideChanged", "Action Tracking", "Reviews Slider");
    },
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () =>
        window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie("uid");
      if (!visitorIdCookie) {
        console.log("[FLAGSHIP GTM] VisitorId is not defined");
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie("xpe");
      const flagshipEventsAPIEndpoint =
        this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: "APP",
            ev: 1,
            t: "EVENT",
            ea,
            ec,
            el,
          }),
        }
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`
        );
      }
    },
  },
};
</script>

<style lang="scss">
.loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 5px solid #206bf6;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #206bf6 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
